<template>
	<div>
		<!-- <b-form-input v-model="test" /> -->
		<filter-fields :filter-options.sync="filtersField" :column-count="4">
			<template #provinsi>
				<v-select
					v-model="selectedProvinsi"
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:options="provinsiOptions"
					class="w-100"
					label="nama"
					:reduce="val => val.kode"
					placeholder="Pilih Provinsi"
				/>
			</template>
			<template #kota>
				<v-select
					v-model="selectedKota"
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:options="kotaOptions"
					class="w-100"
					label="nama"
					:reduce="val => val.kode"
					placeholder="Pilih Kota"
				/>
			</template>
		</filter-fields>
		<b-card>
			<own-table
				ref="caloAgenTable"
				name-table="calon-agens"
				:table-columns="columns"
				:disable-create-btn="true"
				:use-custom-filter="true"
				:custom-query-filter="queryFilter"
				@searchFieldOnChange="searchOnChange"
			>
				<template #cell(no)="data">
					<span>{{ data.data.index + 1 }}</span>
				</template>
				<template #cell(status)="data">
					<b-badge :variant="statusVariant(data.data.item.status)">
						<span>{{ data.data.item.status }}</span>
					</b-badge>
				</template>
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<!-- Dropdown -->
						<b-dropdown
							variant="link"
							toggle-class="p-0"
							menu-class="position-absolute"
							no-caret
							:right="$store.state.appConfig.isRTL"
						>
							<template #button-content>
								<feather-icon
									icon="MoreVerticalIcon"
									size="16"
									class="align-middle text-body"
								/>
							</template>
							<b-dropdown-item @click="setStatus(data.data.item, 'Regist')">
								<!-- <feather-icon icon="action.icon" /> -->
								<span class="align-middle ml-50">Set To Regist</span>
							</b-dropdown-item>
							<b-dropdown-item @click="setStatus(data.data.item, 'In Proses')">
								<!-- <feather-icon icon="action.icon" /> -->
								<span class="align-middle ml-50">Set To Process</span>
							</b-dropdown-item>
							<b-dropdown-item @click="setStatus(data.data.item, 'Agen')">
								<!-- <feather-icon icon="action.icon" /> -->
								<span class="align-middle ml-50">Set To Agen</span>
							</b-dropdown-item>
							<b-dropdown-item @click="setStatus(data.data.item, 'Cancel')">
								<!-- <feather-icon icon="action.icon" /> -->
								<span class="align-middle ml-50">Set To Cancel</span>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>
			</own-table>
		</b-card>
	</div>
</template>
<script>
import {
	BCard,
	BFormInput,
	BBadge,
	BDropdown,
	BDropdownItem,
	BCardTitle,
} from 'bootstrap-vue'
import {
	computed,
	ref,
	onUnmounted,
	watch,
	watchEffect,
} from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import axios from 'axios'
import vSelect from 'vue-select'
import FilterFields from '../components/filter/Filter.vue'
import calonAgenStoreModule from './calonAgenStoreModule'

export default {
	components: {
		BCard,
		FilterFields,
		OwnTable,
		BFormInput,
		vSelect,
		BBadge,
		BDropdown,
		BDropdownItem,
		BCardTitle,
	},
	setup() {
		const CALON_AGEN_STORE_MODULE = 'calon-agen'
		if (!store.hasModule(CALON_AGEN_STORE_MODULE)) {
			store.registerModule(CALON_AGEN_STORE_MODULE, calonAgenStoreModule)
		}
		onUnmounted(() => {
			store.unregisterModule(CALON_AGEN_STORE_MODULE)
		})
		const caloAgenTable = ref(null)
		const regionAPiUrl = ref('https://permohonancustomer.ingco.co.id/public/api')
		const filtersField = ref([
			{
				key: 'provinsi',
				type: 'select',
				model: null,
				opitons: [],
			},
			{
				key: 'kota',
				type: 'select',
				model: null,
				opitons: [],
			},
			{
				key: 'from_date',
				type: 'date',
				model: null,
			},
			{
				key: 'to_date',
				type: 'date',
				model: null,
			},
		])
		const columns = ref([
			{
				label: 'No',
				key: 'no',
			},
			{
				label: 'Nama',
				key: 'nama',
				sortable: true,
			},
			{
				label: 'Toko',
				key: 'toko',
				sortable: true,
			},
			{
				label: 'Provinsi',
				key: 'nama_provinsi',
				sortable: true,
			},
			{
				label: 'Kota/Kabupaten',
				key: 'nama_kab',
				sortable: true,
			},
			{
				label: 'Email',
				key: 'email',
				sortable: true,
			},
			{
				label: 'No HP',
				key: 'no_hp',
				sortable: true,
			},
			{
				label: 'Tanggal Lahir',
				key: 'tgl_lahir',
				sortable: true,
			},
			{
				label: 'Status',
				key: 'status',
				sortable: true,
			},
			{
				label: 'Created At',
				key: 'idt',
				sortable: true,
			},
			{
				label: 'Actions',
				key: 'actions',
			},
		])
		const queryFilter = ref('')
		const provinsiOptions = ref([])
		const selectedProvinsi = ref(null)
		const kotaOptions = ref([])
		const selectedKota = ref(null)
		const search = ref('')

		const statusVariant = status => {
			switch (status.toLowerCase()) {
				case 'regist':
					return 'warning'
				case 'in proses':
					return 'info'
				case 'agen':
					return 'success'
				case 'cancel':
					return 'danger'
				default:
					return ''
			}
		}

		const fetchProvinsi = async () => {
			try {
				const provinsi = await axios.get(`${regionAPiUrl.value}/provinsi`)
				provinsiOptions.value = provinsi.data.data
			} catch (error) {
				console.log(error)
			}
		}
		const fetchKota = async provinsiId => {
			try {
				const kota = await axios.get(
					`${regionAPiUrl.value}/kota?q=&provinsi_id=${provinsiId}`,
				)
				kotaOptions.value = kota.data.data
			} catch (error) {
				console.log(error)
			}
		}

		fetchProvinsi()

		watch(
			[
				...filtersField.value.map(field => () => field.model),
				selectedProvinsi,
				selectedKota,
			],
			(newValue, prevValue) => {
				console.log(newValue)
				if (newValue[4] !== prevValue[4]) {
					selectedKota.value = null
					// eslint-disable-next-line prefer-destructuring
					filtersField.value.find(x => x.key === 'provinsi').model = newValue[4]
					fetchKota(newValue[4])
				}
				if (newValue[5] !== prevValue[5]) {
					// eslint-disable-next-line prefer-destructuring
					filtersField.value.find(x => x.key === 'kota').model = newValue[5]
				}
				queryFilter.value = filtersField.value
					.map(x => (x.model ? `&${x.key}=${x.model}` : ''))
					.join('')
					.concat(`${search.value !== '' ? `&q=${search.value}` : ''}`)
			},
		)
		const searchOnChange = val => {
			console.log(val)
			search.value = val
			queryFilter.value = filtersField.value
				.map(x => (x.model ? `&${x.key}=${x.model}` : ''))
				.join('')
				.concat(`${val !== '' ? `&q=${val}` : ''}`)
		}
		const setStatus = (data, status) => {
			console.log(data)
			store
				.dispatch(`${CALON_AGEN_STORE_MODULE}/setStatus`, { id: data.id, status })
				.then(response => {
					if (response.status === 200) {
						caloAgenTable.value.refetchData()
					}
				})
		}

		return {
			filtersField,
			columns,
			queryFilter,
			caloAgenTable,
			provinsiOptions,
			selectedProvinsi,
			kotaOptions,
			selectedKota,
			searchOnChange,
			statusVariant,
			setStatus,
		}
	},
}
</script>
